/** @jsx jsx */
import { jsx } from 'theme-ui';

import { graphql, useStaticQuery } from 'gatsby';

type Props = {
  site: {
    siteMetadata: {
      siteTitle: string
      siteTitleAlt: string
      siteHeadline: string
      siteUrl: string
      siteDescription: string
      siteLanguage: string
      siteImage: string
      author: string
      [key: string]: unknown
    }
  }
}
// TODO: should move it away when will be required
const useSiteMetadata = () => {
  const data = useStaticQuery<Props>(graphql`
    query {
      site {
        siteMetadata {
          siteTitle
          siteTitleAlt
          siteHeadline
          siteUrl
          siteDescription
          siteLanguage
          siteImage
          author
        }
      }
    }
  `);

  return data.site.siteMetadata;
};

const Footer = () => {
  const { siteTitle } = useSiteMetadata();

  return (
    <footer
      sx={{
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'space-between',
        mt: [6],
        color: 'secondary',
        a: {
          variant: 'links.secondary',
        },
        flexDirection: ['column', 'column', 'row'],
        variant: 'dividers.top',
      }}
    >
      <div>
        &copy;
        {' '}
        {new Date().getFullYear()}
        {' '}
        by
        {' '}
        {siteTitle}
        . All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
